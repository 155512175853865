import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { CloudinaryContext } from 'cloudinary-react';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import { css } from 'emotion'
import Img from 'gatsby-image'
import { capitalize, uniq } from 'lodash'
import SEO from '../components/SEO'
// import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Share from '../components/Share'
import Title from '../components/Title'

const main = css({
  fontSize: '1.2rem',
  wordBreak: 'break-word',

  '.gatsby-image-wrapper': {
    margin: '0.2em',
  },

  h1: {
    marginTop: '1em'
  },

  h5: {
    display: 'inline !important'
  },

  h3: {
    textDecoration: 'underline solid #6039B5',
    textDecorationSkipInk: 'none',
    textUnderlinePosition: 'under'
  },

  '.hora-lugar': {
    margin: '2rem 0'
  },

  'p > img': {
    display: 'block',
    margin: '0 auto',
    maxHeight: '100vh'
  },

  '.card-content .content p': {
    fontSize: '1.1rem'
  },

  '@media all and (max-width: 450px)': {
    fontSize: '1.1rem',
    '.gatsby-image-wrapper': {
      margin: 0,
    },

    '.column': {
      paddingBlockEnd: '0 !important',
      paddingBlockStart: '0 !important'
    },
  },

  '@media all and (min-width: 1300px) and (max-width: 1500px)': {
    fontSize: '1rem'
  }
})

export const EventTemplate = ({
  content,
  contentComponent,
  panelists,
  date,
  time,
  title,
  slug,
  cover_image,
  place,
  helmet,
  gallery
}) => {
  const PostContent = contentComponent || Content

  let smLeft, smRight;
  if (panelists && panelists.panelist) {
    let half = Math.floor(panelists.panelist.length / 2);
    smLeft = panelists.panelist.slice(0, half);
    smRight = panelists.panelist.slice(half, panelists.panelist.length);
  }

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <section className={main}>
      {helmet || ''}
      <div className="content">
        {
          cover_image && <Img fluid={cover_image.childImageSharp.fluid} style={{ height: cover_image.childImageSharp.fluid.presentationHeight }}/>
        }
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Share title={title} slug={slug} />
            <Title title={title} />
            <section className="hora-lugar">
              <h5>FECHA: </h5>
              {capitalize(new Date(`${date} GMT`).toLocaleDateString('es', options))}
              <br />
              <h5>HORA: </h5>
              {time}
              <br />
              <h5>LUGAR: </h5>
              {place}
              <hr />
            </section>
            <PostContent content={content} />
            {
              panelists &&
              <div>
                <hr />
                <h3 className="subtitle is-3 has-text-centered">
                  PANELISTAS
              </h3>
                <div className="columns">
                  <div className="column">
                    {
                      smRight &&
                      smRight.map((g, i) =>
                        <div className="card" key={i}>
                          <header className="card-header has-text-centered">
                            <p className="card-header-title">
                              {g.name}
                            </p>
                          </header>
                          <div className="card-image">
                            {
                              g.photo && <figure className="image">
                                <img src={g.photo} alt="Placeholder" />
                              </figure>
                            }
                          </div>
                          <div className="card-content">
                            <div className="content">
                              {
                                g.bio && g.bio.split('\n\n').map((sentence, i) => <p key={i}>{sentence}</p>)
                              }
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div className="column">
                    {
                      smLeft &&
                      smLeft.map((g, i) =>
                        <div className="card" key={i}>
                          <header className="card-header">
                            <p className="card-header-title">
                              {g.name}
                            </p>
                          </header>
                          <div className="card-image">
                            {
                              g.photo && <figure className="image">
                                <img src={g.photo} alt="Placeholder" />
                              </figure>
                            }
                          </div>
                          <div className="card-content">
                            <div className="content">
                              {
                                g.bio && g.bio.split('\n\n').map((sentence, i) => <p key={i}>{sentence}</p>)
                              }
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            }
            {
              gallery && gallery.length > 0 &&
              <React.Fragment>
                <hr />
                <h3 className="subtitle is-3 has-text-centered">
                  Galería de fotos
                </h3>
                <CloudinaryContext cloudName={process.env.GATSBY_CLOUDINARY_CLOUDNAME}>
                  <ImageGallery items={gallery} className="gallery" />
                </CloudinaryContext>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

EventTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  panelists: PropTypes.shape({
    description: PropTypes.string,
    panelist: PropTypes.array,
  }),
  place: PropTypes.string,
  cover_image: PropTypes.object,
  title: PropTypes.string,
  slug: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  helmet: PropTypes.object
}

export default class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gallery: []
    }
  }
  componentDidMount() {
    const { cloudinary } = this.props.data.markdownRemark.frontmatter;
    let gallery = [];

    if (!cloudinary) return;
    cloudinary.split(',').forEach(tag => {
      axios.get(`https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUDNAME}/image/list/${tag}.json`)
        .then(res => {
          gallery = [];
          res.data.resources.forEach(res => {
            gallery.push({
              original: `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUDNAME}/image/upload/${res.public_id}.jpg`,
              thumbnail: `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUDNAME}/image/upload/h_300,w_400,c_crop/${res.public_id}.jpg`,
              originalAlt: 'foto original evento',
              thumbnailAlt: 'foto miniatura evento',
            })
          });

          gallery = uniq(gallery);
          this.setState({ gallery });
        })
        .catch(err => console.log(err));
    })
  }

  render() {
    const post = this.props.data.markdownRemark;

    return (
      <Layout>
        <EventTemplate
          content={post.html}
          contentComponent={HTMLContent}
          panelists={post.frontmatter.panelists}
          place={post.frontmatter.place}
          date={post.frontmatter.date}
          time={post.frontmatter.time}
          cover_image={post.fields.cover_image_rel}
          helmet={
            <SEO
              isBlogPost={false}
              postData={post}
              postImage={post.fields.cover_image_rel ? post.fields.cover_image_rel.childImageSharp.fluid.src : ''}
            />}
          title={post.frontmatter.title}
          slug={post.fields.slug}
          gallery={this.state.gallery}
        />
      </Layout>
    )
  }
}

Event.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      excerpt
      fields {
        slug
        cover_image_rel {
          childImageSharp {
            fluid(maxHeight: 450){
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationHeight
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        place
        time
        cloudinary
        panelists {
          panelist {
            bio
            name
            photo
          }
        }
      }
    }
  }
`
